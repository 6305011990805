// Styles for InternationalPopup go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';

export const StyledInternationalPopup = styled.div`
  ${rtl`
    .modal-wrapper {
      &:before {
        background-color: transparent;
      }
      position: relative;
    }
    .modal-footer {
      background-color: #fff;
      border-top: none;
      padding-bottom: ${toRem(112)};
      width: fit-content;
      @media ${baseVariables.mediaQuery.md} {
        width: 100%;
      }
      .custom-link {
        text-decoration: underline;
      }
    }
  `}
`;
